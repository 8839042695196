export enum Enum_UI_AI_Category_Tags_Enum {
  Genre = 'Genre',
  Subjects = 'Subjects',
  Colors = 'Colors',
  Emotions = 'Emotions',
}

export enum Enum_DB_AI_Category_Tags_Enum {
  Subjects = 'Subject Matter',
  SubjectMatter = 'SubjectMatter',
  Vibes = 'Vibes',
  Mood = 'Mood',
}

export const EMOTIONS_CATEGORY_TAGS = [Enum_DB_AI_Category_Tags_Enum.Vibes, Enum_DB_AI_Category_Tags_Enum.Mood];

export type AIArtworkTagsUI = {
  [key: string]: {
    [language: string]: TagType[];
  };
};

export type AIArtworkTagsDB = {
  [key: string]: {
    [language: string]: string[];
  };
};

export type CategoryTag = {
  categoryTagEnum: Enum_UI_AI_Category_Tags_Enum;
  tagList: TagType[];
  order: number;
};

export type TagType = {
  id: number;
  name: string;
  value: string;
  originalCategory: string;
};

export type CategoryTagList = Record<Enum_UI_AI_Category_Tags_Enum, TagType[]>;
export const EMPTY_CATEGORY_TAG_LIST = {} as CategoryTagList;
